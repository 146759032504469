import React from 'react'
import "./PopupMic.css";
import close_icon from "../../img/share/close_icon.svg";
import { useShareWindow } from "../../context/ShareWindowContext.js";

const PopupMic = () => {
  const {  showPopup, setShowPopup, isFirstClick, setIsFirstClick} = useShareWindow();

        const closePopup = () => {
        setShowPopup(false);
      };
  return (
    <div className='popup-wrapper'>
        <div className="popup">
          <div className="popup-content">
            <span className="close close-button" onClick={closePopup}><img src={close_icon} alt="Twitter" className="" />
            </span>
            <p>This is your first time clicking the button!</p>
          </div>
        </div>
    </div>

  )
}

export default PopupMic